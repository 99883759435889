import React, {forwardRef} from 'react';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { useSelector, useDispatch } from 'react-redux';
import { getMyNotifications } from "../../../Redux/actions";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function Enviados() {
  // const [tableRef, setTableRef] = React.useState(null);
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);

  const {Notification} = useSelector(state => state);
  const dispatch = useDispatch();

  const getNotifications = () => {
    dispatch(getMyNotifications({limit, page}));
  }

  React.useEffect(() => {
    getNotifications();
  }, [page, limit]); 

  return (
    <MaterialTable
      icons={tableIcons}
      title="Notificações enviadas"
      // tableRef={setTableRef}
      isLoading={Notification.loading}
      options={{search: false}}
      columns={[
        {
          field: 'url',
          title: 'App',
          render: rowData => (
            <b>{rowData.app_name}</b>
          )
        },
        { title: 'código', field: 'codigo' },
        { title: 'Título', field: 'title' },
        { title: 'Corpo', field: 'body' },
        { title: 'Enviado', field: 'created_at', type: 'datetime' },
      ]}
      data={Notification.list}
      onChangeRowsPerPage={(e) => setLimit(e)}
      localization={{
        pagination: {
          labelRowsSelect: 'linhas',
          labelDisplayedRows: '{count} de {from}-{to}',
          firstTooltip: 'Primeira página',
          previousTooltip: 'Página anterior',
          nextTooltip: 'Próxima página',
          lastTooltip: 'Última página'
        },
        toolbar: {
          nRowsSelected: '{0} linhas(s) selecionadas'
        },
        header: {
          actions: 'Ações'
        },
        body: {
          emptyDataSourceMessage: 'Não há dados',
          filterRow: {
            filterTooltip: 'Filter'
          }
        }
      }}
      components={{
        Pagination: props => <TablePagination 
          {...props}
          count={Notification.total} 
          page={(page-1)}
          onChangePage={(e, page) => setPage(page+1)} />
      }}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Atualizar',
          isFreeAction: true,
          onClick: () => getNotifications(),
        }
      ]}
    />
  );
}
