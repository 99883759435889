import React, {useState} from 'react';
import {
  Container, 
  Typography, 
  Box, 
  Grid,
  TextField, 
  CssBaseline, 
  Button, 
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from '@material-ui/core/styles';
import theme from "../../Theme";

import { Link, Copyright, Alert } from "../../Components";
import { login, sendNewPassword } from "../../Redux/actions";
import useStyles from "./styles";
import "./styles.css"

export default function SignIn() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const {Auth} = useSelector(state => state)

  const onLogin = (e) => {
    dispatch(login({email, password}));
    e.preventDefault();
  }

  const [viewModalResetPassword, setViewModalResetPassword] = React.useState(false);
  const [emailResetPassword, setEmailResetPassword] = React.useState('');

  const sendPassword = async () => {
    await setEmail(emailResetPassword)
    await dispatch(sendNewPassword({email: emailResetPassword}))
  }

  const resetPassword = async () => {
    await setEmailResetPassword('')
    await setViewModalResetPassword(true)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" noValidate maxWidth="xs" style={{backgroundColor: '#fff', borderRadius: 5}}>
        <CssBaseline />
        <div className={classes.paper}>
          <IconButton color="inherit" className={classes.iconButtonAvatar}>
            <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg" />
          </IconButton>
          
          <Typography style={{color: "gray"}} component="h1" variant="h5">
            Acessar conta
          </Typography>

          <form onSubmit={onLogin}>

          <TextField
            error={Auth.errorEmail}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email de acesso"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={text => setEmail(text.target.value)}
          />

          <TextField
            error={Auth.errorPassword}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha de acesso"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={text => setPassword(text.target.value)}
          />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {!!Auth.loading ? 
              <>
              <CircularProgress
                style={{marginRight: 10, color: "#ffffff"}}
                variant="indeterminate"
                disableShrink
                // color="secundary"
                size={15}
                thickness={4}/>
                Por favor aguarde
              </>
              : "Acessar agora" }
          </Button>

          </form>

          <Grid container>
            <Grid style={{paddingBottom: 5}} item sm={12}>
              <Link className={classes.link} onClick={resetPassword}>
                Esqueci minha senha?
              </Link>
            </Grid>
            {/* <Grid sm={12}>
              <Link className={classes.link} to="/signup">
                Não tem um app conosco? Cadastre-se agora.
              </Link>
            </Grid> */}
          </Grid>
        </div>

        <Box mt={2} style={{paddingBottom: 10}}>
          <Copyright />
        </Box>

        <Alert open={(!!Auth.errorMsg.length)} message={Auth.errorMsg} />
      </Container>

      <Dialog open={!!viewModalResetPassword}>
        <DialogTitle id="alert-dialog-title">
            Resetar senha
            <div style={{fontSize: 15 ,color: "gray"}}>
              Enviaremos um email contendo informações sobre a redefinição de senha
            </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <TextField
            style={{width: "100%"}}
            id="PasswordField" 
            label="Digite seu email de acesso!" 
            variant="outlined"
            value={emailResetPassword}
            onChange={text => setEmailResetPassword(text.target.value)}
            size="small" />
          </DialogContentText>
        </DialogContent>
    
        <DialogActions>
          <Button onClick={() => setViewModalResetPassword(false)} color="primary">
            Fechar
          </Button>
          <Button disabled={Auth.loading} onClick={()=> sendPassword()} variant="contained" color={"primary"}>
            {!!Auth.loading
              ? <><CircularProgress color="#fff" style={{width: 20, height: 20, marginRight: 10}} /> Enviando...</>
              : " Enviar solicitação"
            }
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
