import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';

// const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;
  
const CustomizedSnackbars = (props) => {
    return (
        <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        severity={props.type}
        open={props.open}
        message={props.message}
      />
        // <Snackbar open={props.open}>
        //     Ola mundo
        //     {/* {props.type === "error" && <Alert severity="error">This is an error message!</Alert>}
        //     {props.type === "warning" && <Alert severity="warning">This is a warning message!</Alert>}
        //     {props.type === "info" && <Alert severity="info">This is an information message!</Alert>}
        //     {props.type === "success" && <Alert severity="success">This is a success message!</Alert>} */}
        // </Snackbar>
    );
}

export default CustomizedSnackbars;