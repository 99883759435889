import React from 'react';
import {
    Paper,
    Typography,
    TextField,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import { Alert as Alerta, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, SimulatorDevice } from "../../../Components";
import imgAndroid from "../../../Assets/img/android.png";
import { getMyApps, sendNotification } from "../../../Redux/actions";
import api from "../../../Service";
import store from "../../../Redux/store";


const CriarNotificacaoText = (props) => {
    const {classes} = props
    
    const [error, setError] = React.useState(false);
    
    const [app, setApp] = React.useState(`-1`);
    const [title, setTitle] = React.useState(``);
    const [body, setBody] = React.useState(``);
    
    const [dialog, setDialog] = React.useState(false);  
    const { Notification, Apps } = useSelector(state => state);
    const dispatch = useDispatch();


    const SendNotificationProd = async (e) => {
        if(app === "-1"){
            setError("É necessário selecionar um aplicativo.")
            setTimeout(() => setError(null), 6000)
        }else if(title.length === 0){
            setError("É necessário o campo Título da notificação.")
            setTimeout(() => setError(null), 6000)
        }else if(body.length === 0){
            setError("É necessário o campo Corpo da notificação.")
            setTimeout(() => setError(null), 6000)
        }else{
            setDialog(true);
            dispatch(sendNotification({app, title, body}))
        }
        e.preventDefault();
    }

    const closeNotificationEnvio = () => {
        setTitle('');
        setBody('');
        setApp('-1');
        setDialog(false);
        store.dispatch({type: "RES_GETMYNOTIFICATIONS", payload: {error: "", success: ""}})
    }

    React.useEffect(() => {
        Apps.apps.length === 0 && !Apps.apps.loading && dispatch(getMyApps());
    }, [])

    const appSelected = Apps.apps.filter((item) => item.id === app);
    const appName = (app !== "-1") && appSelected[0].name;
    const appIcLauncher = (app !== "-1") &&  !!appSelected[0].ic_launcher && api.defaults.baseURL+appSelected[0].ic_launcher;

    return (
        <Grid container>
            <Alerta severity="warning" elevation={2} className={classes.paper} style={{paddingBottom: 5, marginBottom:20}}>
                <AlertTitle>Atenção</AlertTitle>
                <ul>
                    {(app !== "-1") && <li>Sua cota de envio para o app <b>{appSelected[0].name}</b> é de <b>{appSelected[0].qtd_day_notification}</b> {appSelected[0].qtd_day_notification > 1 ? "notificações" : "notificação" } por dia.</li>}
                    <li>Você enviará uma notificação para todos usuários do app selecionado.</li>
                </ul>
            </Alerta>

            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item md={12} className={classes.header}>
                        <Typography variant="h6" gutterBottom>
                            Enviar notificação
                        </Typography>
                    </Grid>
                    
                    <Grid item spacing={5} md={6}>
                        <form noValidate onSubmit={SendNotificationProd} autoComplete="off">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Selecione um aplicativo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={app}
                                    onChange={(e) => setApp(e.target.value)}
                                    disabled={Apps.loading}
                                    label="Selecione um aplicativo">
                                    <MenuItem value="-1"><em>{!!Apps.loading ? "Carregando..." : "Selecione"}</em></MenuItem>
                                    {Apps.apps.map(({name, id}) => <MenuItem value={id}>{name}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <div style={{paddingTop: 20}} className={classes.inputSpace}>
                                <TextField 
                                    className={classes.input} 
                                    id="titleField" 
                                    label="Título da notificação" 
                                    variant="outlined"
                                    value={title}
                                    onChange={text => setTitle(text.target.value.substr(0, 35))} />
                            </div>
                            
                            <div className={classes.inputSpace}>
                                <TextField 
                                    className={classes.input} 
                                    id="bodyField" 
                                    label="Corpo da notificação" 
                                    variant="outlined"
                                    multiline
                                    rows="4"
                                    value={body}
                                    onChange={text => setBody(text.target.value.substr(0, 320))}
                                    size="small" />
                                
                                <div style={{textAlign: "right", color: "gray"}}>
                                    {body.length} caracteres digitado, máximo 320
                                </div>
                            </div>

                            <div className={classes.inputSpace}>
                                <Button 
                                    type="submit"
                                    variant="contained" color="primary">
                                    Enviar Notificação
                                </Button>
                            </div>
                        </form>
                    </Grid>

                    <Grid item md={6} style={{padding: 7}}>
                        <Paper> 
                            <Grid style={{padding: 20}}>
                                <div>
                                    <Typography variant="subtitle2" style={{fontWeight: "bold"}} gutterBottom>
                                        Visualização no dispositivo
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="caption" gutterBottom>
                                        Por meio dessa visualização, é possível ter uma ideia de 
                                        como sua mensagem será exibida em um dispositivo móvel. 
                                        Haverá variação na renderização real da mensagem dependendo do dispositivo. 
                                    </Typography>
                                </div>
                            </Grid>
                            <SimulatorDevice noImage icon={appIcLauncher} title={title} body={body} appName={appName} />
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
            
            {!!dialog &&
            <Dialog open={true}>
                
                {!!Notification.error &&
                <>
                    <DialogTitle id="alert-dialog-title" style={{color: "#f50057"}}>
                        Ocorreu um erro
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {Notification.error}
                        </DialogContentText>
                    </DialogContent>
                </>
                }

                {!!Notification.success &&
                <>
                    <DialogTitle id="alert-dialog-title" style={{color: "#22d0b9"}}>
                        Mensagem Enviada
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Mensagem enviada com sucesso para o aplicativo <b>{Apps.apps.filter(i => i.id === app)[0].name}</b>.
                        </DialogContentText>
                    </DialogContent>
                </>
                }

                {!Notification.error && !Notification.success &&
                <>
                    <DialogTitle id="alert-dialog-title">
                        Enviando Notificacão
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sua notificação de texto está sendo enviada pra todos os usuários do 
                            aplicativo <b>{Apps.apps.filter(i => i.id === app)[0].name}</b>, por favor aguarde.
                        </DialogContentText>
                    </DialogContent>
                </>
                }

                <DialogActions>
                    <Button disabled={Notification.loading} onClick={closeNotificationEnvio} variant="contained" color={"primary"}>
                        {!!Notification.loading 
                            ? <><CircularProgress color="#fff" style={{width: 20, height: 20, marginRight: 10}} /> Enviando...</>
                            : "Ok, Fechar!"
                        }
                    </Button>
                </DialogActions>
            </Dialog>}
            
            <Alert open={!!error} message={error} />
        </Grid>
    );
}

const styles = (theme) => ({
    paper: {
      maxWidth: 936,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 40,
      margin: 'auto',
      overflow: 'hidden',
    },
    header: {
        marginTop: 20
    },
    inputSpace: {
        padding: "10px 0px 10px 0px"
    },
    input: {
        width: "100%"
    },
    contentWrapper: {
      margin: '25px 16px',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    expanActive: {
        padding: "10px 20px", 
        borderWidth: 1, 
        color: "#1a73e8", 
        borderColor: "#e9f4ff", 
        backgroundColor: "#dae5f4", 
        borderRadius: 10,
        cursor: "pointer"
    },
    expanNoActive: {
        color: "gray", 
        padding: "10px 20px", 
        cursor: "pointer"
    },
    notification: {marginTop: 72, padding: 10, marginBottom: 6, backgroundColor: "#fff"},
    android: {marginTop: 40, paddingLeft: 25, paddingRight: 25, backgroundRepeat: "no-repeat", backgroundSize: "100%", display: "flex", backgroundImage: `url(${imgAndroid})`},
    notificationInfo: {position: "absolute", marginTop: -1, marginLeft: 8, color: "gray"},
    formControl: {
        // margin: theme.spacing(1),
        minWidth: "100%",
      },
      selectEmpty: {
        marginTop: theme.spacing(10),
      },
});

export default withStyles(styles)(CriarNotificacaoText);