import React from 'react';
import { Container, Header, Content } from "../../Components";

import MeusApps from "./MeusApps";
// import SolicitarApp from "./SolicitarApp";

const Anunciar = () => {
  const [tabIndex, setTabIndex] = React.useState(0); 

  const tabs = [
    { id: "Meus Apps", component: <MeusApps /> },
    // { id: "Solicitar App", component: <SolicitarApp /> }
  ]

  return (
    <Container>
      <Header 
        title="Bem Vindo" 
        tabs={tabs}
        tabIndexCurrent={tabIndex}
        onClickTab={tabindex => setTabIndex(tabindex)} />
      <Content>
        {tabs[tabIndex].component}
      </Content>
    </Container>
  );
}

export default Anunciar;
