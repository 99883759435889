import Auth from './Auth/reducer';
import User from './User/reducer';
import Notification from './Notification/reducer';
import Apps from './Apps/reducer';
import Servicos from './Servicos/reducer';

const reducers = {
  Auth,
  User,
  Notification,
  Apps,
  Servicos
};

export default reducers;