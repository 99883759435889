import theme from "../../Theme";

const styles = {
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: 256,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    footer: {
      padding: theme.spacing(2),
      background: '#eaeff1',
    },
    link: {
      color: '#fff !important',
      fontWeight: "bold"
    }
};  

export default styles;