import {REQ_LOGIN, RES_LOGIN, REQ_REGISTER, RES_REGISTER, REQ_LOGOUT, RES_LOGOUT, REQ_SENDNEWPASSWORD, RES_SENDNEWPASSWORD} from '../actionsTypes';

const INIT_STATE = {
   loading: false,
   name: "",
   email: "",
   password: "",
   errorName: false,
   errorEmail: false,
   errorPassword: false,
   errorPasswordConfirm: false,
   errorMsg: "",
   successMsg: ""
}

export default (state = INIT_STATE, action) => {
   switch (action.type) {
      case REQ_LOGIN:
         return { ...state, loading: true };

      case RES_LOGIN:
         return { ...state, ...action.payload, loading: false };

      case REQ_SENDNEWPASSWORD:
         return { ...state, loading: true };

      case RES_SENDNEWPASSWORD:
         return { ...state, ...action.payload, loading: false };
      
      case REQ_REGISTER:
         return { ...state, loading: true };

      case RES_REGISTER:
         return { ...state, ...action.payload, loading: false };
   
      case REQ_LOGOUT:
         return { ...state, Loading: true };
      
      case RES_LOGOUT:
         return { ...state, Loading: false };

      default:
         return state;
   }
}