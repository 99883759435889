//AUTH
export const REQ_LOGIN = "REQ_LOGIN";
export const RES_LOGIN = "RES_LOGIN";
export const REQ_REGISTER = "REQ_REGISTER";
export const RES_REGISTER = "RES_REGISTER";
export const REQ_LOGOUT = "REQ_LOGOUT";
export const RES_LOGOUT = "RES_LOGOUT";
export const REQ_SENDNEWPASSWORD = "REQ_SENDNEWPASSWORD";
export const RES_SENDNEWPASSWORD = "RES_SENDNEWPASSWORD";

// USER
export const REQ_GETME = "REQ_GETME";
export const RES_GETME = "RES_GETME";

// NOTIFICATION
export const REQ_SENDNOTIFICATION = "REQ_SENDNOTIFICATION";
export const RES_SENDNOTIFICATION = "RES_SENDNOTIFICATION";

// APPS
export const REQ_GETMYAPPS = "REQ_GETMYAPPS";
export const RES_GETMYAPPS = "RES_GETMYAPPS";

// NOTIFICATION
export const REQ_GETMYNOTIFICATIONS = "REQ_GETMYNOTIFICATIONS"
export const RES_GETMYNOTIFICATIONS = "RES_GETMYNOTIFICATIONS"

// SERVICOS
export const REQ_GETSERVICOS = "REQ_GETSERVICOS"
export const RES_GETSERVICOS = "RES_GETSERVICOS"