import React from 'react';
import {
  Container, 
  Typography, 
  Box, 
  CssBaseline, 
  Button,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Copyright } from "../../Components";
import { useParams } from "react-router-dom";
import jwt from 'jsonwebtoken';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    alignItems: 'center',
    alignContent: "center",
    justifyContent: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
    const classes = useStyles()
    const {utoken} = useParams()
    const [loading, setLoading] = React.useState(true);
    const [deepLink, setDeepLink] = React.useState("");

    React.useEffect(() => {
      setTimeout(() => {
        jwt.verify(
          utoken, 
          'kdhgdjdfl;Akfjskdghhdgkd', 
          function(err, decoded) {
            setLoading(false)
            setDeepLink(decoded.data.deep_link)
          }
        );
      }, 2000);
    }, []);

  return (
    <Container component="main" noValidate maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        
        <Typography style={{marginTop: "30vh" }} component="h1" variant="h6">
            Para testar a notificação, clique em testar agora.
        </Typography>


        <Button
            href={`${deepLink}notificationtest/${utoken}`}
            disabled={!!loading}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit} >
              {!!loading ? 
              <>
                <CircularProgress
                  style={{marginRight: 10, color: "gray"}}
                  variant="indeterminate"
                  disableShrink
                  // color="secundary"
                  size={15}
                  thickness={4}/>
                  Por favor aguarde
              </> : 
              "Testar agora"}
        </Button>

      </div>

      <Box mt={8}>
        <Copyright />
      </Box>

    </Container>
  );
}
