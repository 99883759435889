
import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { 
    REQ_GETMYNOTIFICATIONS, 
    RES_GETMYNOTIFICATIONS,
    REQ_SENDNOTIFICATION,
    RES_SENDNOTIFICATION
} from '../actionsTypes';
import api from "../../Service";

const asyncSendNotification = async payload => {
    const { data } = await api.post('/graphql', {query: 
        `mutation notificacao {
            notificacao(app_id: ${payload.app}, title: "${payload.title}", body: "${payload.body}", image: "${!!payload.imageUrl ? payload.imageUrl : ""}") {
                id
                title
                body
                created_at
                success
                error
            }
        }`
    })

    return data.data.notificacao;
}
function* sendNotification({payload}) {
    try {
        const data = yield call(asyncSendNotification, payload);
        yield put({ type: RES_GETMYNOTIFICATIONS, payload: {error: data.error, success: data.success} });
        yield put({ type: RES_SENDNOTIFICATION });
    } catch (error) {
        yield put({ type: RES_GETMYNOTIFICATIONS, payload: {error: "Ocorreu um erro ao tentar acessar o servidor."} });
    }
}


const asyncGetMyNotifications = async payload => {
    const { data } = await api.post('/graphql', {query: 
        `query notificacao {
            notificacao(me: true, limit: ${payload.limit}, page: ${payload.page}){
                data {
                    id
                    app_name
                    app_img
                    codigo
                    title
                    body
                    created_at
                }
                total
                per_page
            }
        }`
    })
    return data.data.notificacao;
}
function* getMyNotifications({payload}) {
    try {
        const {data, per_page, total} = yield call(asyncGetMyNotifications, payload);
        yield put({ type: RES_GETMYNOTIFICATIONS, payload: {list: data, per_page, total} });
    } catch (error) {
        // console.log(error);
    }
}

export function* watchSendNotification() {
    yield takeLatest(REQ_SENDNOTIFICATION, sendNotification);
}

export function* watchGetMyNotifications() {
    yield takeLatest(REQ_GETMYNOTIFICATIONS, getMyNotifications);
}


export default function* rootSaga() {
   yield all([
        fork(watchSendNotification),
        fork(watchGetMyNotifications)
   ]);
}