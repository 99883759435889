import { createStore, applyMiddleware, combineReducers} from 'redux';
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";

import history from "../Router/history";
import reducers from './reducers';
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers
});
const middleware = [
    sagaMiddleware,
    routerMiddleware(history)
]

const store = createStore(
    connectRouter(history)(rootReducer), 
    applyMiddleware(...middleware)
);

export default store;
sagaMiddleware.run(sagas);