import {REQ_GETME, RES_GETME} from '../actionsTypes';

const INIT_STATE = {
   loading: false,
   name: "",
   email: ""
}

export default (state = INIT_STATE, action) => {
   switch (action.type) {
      case REQ_GETME:
         return { ...state, loading: true };

      case RES_GETME:
         return { ...state, ...action.payload, loading: false };

      default:
         return state;
   }
}