import React from 'react';
import {Link} from "react-router-dom";

const styles = {
    color: "#3f51b5",
    textDecoration: "none"
};

const ComponentLink = (props) => (
    <Link 
        style={styles}
        {...props}>
        {props.children}
    </Link>
);

export default ComponentLink;