import React from 'react';
import { Container, Header, Content } from "../../../Components";

import Enviadas from "./Enviadas";
import Enviar from "./Enviar";

const Anunciar = () => {
  const [tabIndex, setTabIndex] = React.useState(0); 

  const tabs = [
    { id: "Novo", component: <Enviar onChangePage={(i) => setTabIndex(i)} /> },
    { id: "Enviados", component: <Enviadas /> }
  ]

  return (
    <Container>
      <Header 
        title="Notificação com imagem" 
        tabs={tabs}
        tabIndexCurrent={tabIndex}
        onClickTab={tabindex => setTabIndex(tabindex)} />
      <Content>
        {tabs[tabIndex].component}
      </Content>
    </Container>
  );
}

export default Anunciar;
