import React from 'react';
import PropTypes from 'prop-types';
import {
  Hidden, 
  CssBaseline, 
  Avatar, 
  Grid, 
  // Tooltip, 
  AppBar, 
  Toolbar, 
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton';

import theme from "../../Theme";
import styles from "./styles";

import { getMe, logout, sendNewPassword } from "../../Redux/actions";
import { Copyright, Navigator, Alert } from "../../Components";


const Container = (props) => {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menu, setMenu] = React.useState(null);

  const dispatch = useDispatch();
  const { User, Auth } = useSelector(state => state);

  
  const menuOpen = (event) => {
    setMenu(event.currentTarget);
  };
  const menuClose = () => {
    setMenu(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = () => {
    menuClose();
    dispatch(logout());
  }

  React.useEffect(() => {
    !User.name && dispatch(getMe("name email"))
  }, [])

  const [viewModalNewPassword, setViewModalNewPassword] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [typeAlert, setTypeAlert] = React.useState(null);
  const sendPassword = () => {
    dispatch(sendNewPassword({password}));
    setPassword("");
  }

  if(!alertMsg && (!!Auth.errorMsg || !!Auth.successMsg)){
    setAlertMsg(Auth.errorMsg || Auth.successMsg);
    setViewModalNewPassword(false);
    setTypeAlert(!!Auth.errorMsg ? "error" : "success")
    setTimeout(() => {
      setAlertMsg("");  
    }, 3000);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        
        <Navigator
          classes={classes}
          mobileOpen={mobileOpen} 
          handleDrawerToggle={handleDrawerToggle} />

        <div className={classes.app}>
          <AppBar color="primary" position="sticky" elevation={0}>
            <Toolbar>
              <Grid container spacing={1} alignItems="center">
                <Hidden smUp>
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerToggle}
                      className={classes.menuButton}>
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
                <Grid item xs />
                
                <Grid item>
                {!!User.loading
                    ? <Skeleton width={150} variant="text" animation="wave" />
                    : User.name
                  }
                </Grid>
                
                <Grid item>
                    
                  {!!User.loading
                    ? <Skeleton variant="circle" width={40} height={40} animation="wave" /> 
                    : <IconButton onClick={menuOpen} color="inherit" className={classes.iconButtonAvatar}>
                        <Avatar src="/static/images/avatar/1.jpg" alt={User.name} />
                      </IconButton>
                  }
                  <Menu
                    style={{width: 200, marginTop: 30}}
                    id="simple-menu"
                    anchorEl={menu}
                    keepMounted
                    open={Boolean(menu)}
                    onClose={menuClose}
                  >
                    <MenuItem onClick={() => {setViewModalNewPassword(true); menuClose();}}>Alterar senha</MenuItem>
                    <MenuItem onClick={onLogout}>Sair do sistema</MenuItem>
                  </Menu>
                </Grid>

              </Grid>
            </Toolbar>
          </AppBar>
          
          {props.children}
          
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>

      <Dialog open={viewModalNewPassword}>
          <DialogTitle id="alert-dialog-title">
              Alterar Senha
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
              <TextField
                style={{width: "100%"}}
                id="PasswordField" 
                label="Nova senha" 
                variant="outlined"
                value={password}
                onChange={text => setPassword(text.target.value)}
                size="small" />
              </DialogContentText>
          </DialogContent>
      
          <DialogActions>
            <Button onClick={() => setViewModalNewPassword(false)} color="primary">
              Fechar
            </Button>
            <Button disabled={Auth.loading} onClick={()=> sendPassword()} variant="contained" color={"primary"}>
                {!!Auth.loading
                    ? <><CircularProgress color="#fff" style={{width: 20, height: 20, marginRight: 10}} /> Altendado...</>
                    : " Alterar Senha"
                }
            </Button>
          </DialogActions>
      </Dialog>

      <Alert open={alertMsg} message={alertMsg} type={typeAlert} />
    </ThemeProvider>
  );
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Container);
