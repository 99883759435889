import React from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, IconButton, Typography, Grid} from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import Skeleton from '@material-ui/lab/Skeleton';
import api from "../../Service";
import { useDispatch, useSelector } from 'react-redux'
import { getMyApps } from "../../Redux/actions";
import iconAppPlaceholder from "../../Assets/img/ic_app_placeholder.png";

import { Alert } from "../../Components";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: 230
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
        alignSelf: "center",
        width: 100,
        height: 100,
        marginRight: 15,
        borderRadius: 20
    },
    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }
}));

const MyApps = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch(); 
    const [error] = React.useState(false);
    const { Apps } = useSelector(state => state);

    React.useEffect(() => {
        Apps.apps.length === 0 && !Apps.apps.loading && dispatch(getMyApps());
    }, []);

    return (
        <Grid container justify="center" spacing={3}>
            {!!Apps.loading && [1].map(() => (
                <Grid item>
                    <Card className={classes.root}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    <Skeleton width={180} variant="text" animation="wave" />
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    <Skeleton width={150} variant="text" animation="wave" />
                                </Typography>
                            </CardContent>
                            <div className={classes.controls}>
                                <IconButton aria-label="android">
                                    <Skeleton width={15} variant="text" animation="wave" />
                                </IconButton>

                                <IconButton aria-label="android">
                                    <Skeleton width={15} variant="text" animation="wave" />
                                </IconButton>
                            </div>
                        </div>
                        <Skeleton className={classes.cover} variant="square" animation="wave" />
                    </Card>
                </Grid>
            ))}
            
            {!Apps.loading && 
                Apps.apps.map(({name, category, link_googleplay, link_applestore, ic_launcher}) => (
                <Grid item>            
                    <Card className={classes.root}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    {name}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {category}
                                </Typography>
                            </CardContent>
                            <div className={classes.controls}>
                                {!!link_googleplay &&
                                <IconButton aria-label="android" onClick={() => window.open(link_googleplay, '_blank')}>
                                    <AndroidIcon  style={{color: "lightgray"}} />
                                </IconButton>}
                                {!!link_applestore &&
                                <IconButton aria-label="apple" onClick={() => window.open(link_applestore, '_blank')}>
                                    <AppleIcon  style={{color: "lightgray"}} />
                                </IconButton>}
                            </div>
                        </div>
                        <img
                            className={classes.cover}
                            src={!!ic_launcher ? api.defaults.baseURL+ic_launcher : iconAppPlaceholder}
                            title="Live from space album cover"
                            alt="icone do app"
                        />
                    </Card>
                </Grid>
                ))
            }
            
            
            <Alert open={!!error} message={error} />
        </Grid>
    );
}

const styles = (theme) => ({
});

export default withStyles(styles)(MyApps);