
import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import {REQ_GETMYAPPS, RES_GETMYAPPS} from '../actionsTypes';
import api from "../../Service";

const asyncGetMyApps = async payload => {
    const { data } = await api.post('/graphql', {query: 
        `query {
            apps(me: true){
              id
              name
              category
              link_googleplay
              link_applestore
              ic_launcher
              ic_notification
              deep_link
              qtd_day_notification
            }
        }`
    });
    return data.data;
}
function* getMyApps({payload}) {
    try {
        const {apps} = yield call(asyncGetMyApps, payload);
        yield put({ type: RES_GETMYAPPS, payload: {apps}});
    } catch (error) {
        console.log(error);
    }
}


export function* watchGetMyApps() {
    yield takeEvery(REQ_GETMYAPPS, getMyApps);
}

export default function* rootSaga() {
   yield all([
        fork(watchGetMyApps)
   ]);
}