
import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { REQ_GETME, RES_GETME } from '../actionsTypes';
import api from "../../Service";

const asyncGetMe = async payload => {
    const { data } = await api.post('/graphql', {query: 
        `query Users {
            users(me: true) {
              ${payload}
            }
        }`
    })
    return data.data.users;
}

function* getMe({payload}) {
    try {
        const me = yield call(asyncGetMe, payload);
        yield put({ type: RES_GETME, payload: me[0]});
    } catch (error) {
        console.log(error);
    }
}

export function* watchGetMe() {
    yield takeEvery(REQ_GETME, getMe);
}


export default function* rootSaga() {
   yield all([
        fork(watchGetMe)
   ]);
}