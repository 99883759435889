import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from '../Pages/Auth';
import { ConnectedRouter } from "connected-react-router";
import history from "./history";
import {myRoutes} from "./myRoutes";

import Home from "../Pages/Home";
import ConnectApp from "../Pages/ConnectApp";

const NoMatchPage = () => {
    return (
        <h3>404 - Not found</h3>
    );
};

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route 
        {...rest} 
        render={props => {
            if(!!isAuthenticated())
                return <Component {...props} />
            else
                return <Redirect to={{pathname: "/signin", state: {from: props.location}}}/>
        }} 
    /> 
);

const Routes = () => {
    const list = {public: [], auth: []};

    myRoutes.map(({children}) => {
        children.map(item => {
            if(!!item.isPublic)
                list.public.push(item)
            else
                list.auth.push(item)
            
            return null;
        })
        return null;
    });

    return (
        <ConnectedRouter history={history}>
            <Switch>
                {list.public.map(({id, path, component: Component}) => (
                    <Route key={id} exact path={path} component={Component} />  
                ))}
                
                {list.auth.map(({id, path, component: Component}) => (
                    <PrivateRoute key={id} exact path={path} component={Component} />  
                ))}

                <Route exact path="/connectapp/:utoken" component={ConnectApp} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/dashboard" component={Home} />
                <Route component={NoMatchPage} />
            </Switch>
        </ConnectedRouter>
    )
};

export default Routes;