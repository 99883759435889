import {
   // REQ_SENDNOTIFICATION, 
   // RES_SENDNOTIFICATION,
   REQ_GETSERVICOS,
   RES_GETSERVICOS
} from '../actionsTypes';

const INIT_STATE = {
   loading: false,
   list: [],
   per_page: 0,
   limit: 5,
   error: "",
   success: ""
}

export default (state = INIT_STATE, action) => {
   switch (action.type) {
      // case REQ_SENDNOTIFICATION:
      //    return { ...state, loading: true };

      // case RES_SENDNOTIFICATION:
      //    return { ...state, loading: false };

      case REQ_GETSERVICOS:
         return { ...state, loading: true };

      case RES_GETSERVICOS:
         return { ...state, ...action.payload, loading: false };

      default:
         return state;
   }
}