import { all } from 'redux-saga/effects';
import sagaAuth from './Auth/saga';
import sagaUser from './User/saga';
import sagaNotification from './Notification/saga';
import sagaApps from './Apps/saga';
import sagaServicos from './Servicos/saga';

export default function* rootSaga() {
  yield all([
    sagaAuth(),
    sagaUser(),
    sagaNotification(),
    sagaApps(),
    sagaServicos()
  ]);
}