import React from "react";
import PeopleIcon from '@material-ui/icons/People';

// AUTH
import SignIn from "../Pages/Auth/SignIn";
// import SignUp from "../Pages/Auth/SignUp";

// HOME
import Home from "../Pages/Home";

// ANUNCIAR
import NotificacaoTexto from "../Pages/Anunciar/NotificacaoTexto";
import NotificacaoImagem from "../Pages/Anunciar/NotificacaoImagem";

// ADMINISTRACAO
// import Usuarios from "../Pages/Usuarios";

//SERVICOS
// import ServicosContratados from "../Pages/Servicos/ServicosContratados";

export const home = { 
    id: 'Início',
    path: '/dashboard',
    icon: <PeopleIcon />,
    blank: false,
    component: Home
}

export const myRoutes = [
    {
        id: 'Auth',
        isCategories: false,
        children: [
            { 
                id: 'SignIn',
                path: '/signin',
                icon: <PeopleIcon />,
                isPublic: true,
                component: SignIn,
            },
            // { 
            //     id: 'SignUp',
            //     path: '/signup',
            //     icon: <PeopleIcon />,
            //     isPublic: true,
            //     component: SignUp,
            // }
        ],
    },
    {
        id: 'Notificação',
        isCategories: true,
        children: [
            { 
                id: 'Notificação de texto',
                path: '/notificacaotexto',
                icon: <PeopleIcon />,
                isPublic: false,
                component: NotificacaoTexto
            },
            { 
                id: 'Notificação com imagem',
                path: '/notificacaoimagem',
                icon: <PeopleIcon />,
                isPublic: false,
                component: NotificacaoImagem
            },
        ],
    },
    // {
    //     id: 'Serviços',
    //     isCategories: true,
    //     children: [
    //         { 
    //             id: 'Serviços Contratato',
    //             path: '/servicos',
    //             icon: <PeopleIcon />,
    //             isPublic: false,
    //             component: ServicosContratados
    //         }
    //     ],
    // },
    // {
    //     id: 'Administracao',
    //     isCategories: true,
    //     children: [
    //         { 
    //             id: 'Usuarios',
    //             path: '/usuarios',
    //             icon: <PeopleIcon />,
    //             isPublic: false,
    //             component: Usuarios
    //         },
    //     ],
    // }
];