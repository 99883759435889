import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';

import { Link } from "../../Components";
import {myRoutes, home} from "../../Router/myRoutes"
import "./style.css"

function CustomDrawer(props) {
  const { classes, ...other } = props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>

        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <span className="logo">Loreweb</span> <SmsOutlinedIcon />
        </ListItem>
        
        <Link to="/dashboard">
          <ListItem 
            style={
              (window.location.pathname === "/dashboard" || window.location.pathname === "/") ? 
              {color: "#4fc3f7"} : {}
            } className={clsx(classes.item, classes.itemCategory)}>
            <ListItemIcon className={classes.itemIcon}>
              {home.icon}
            </ListItemIcon>
            <ListItemText classes={{primary: classes.itemPrimary}}>
              {home.id}
            </ListItemText>
          </ListItem>
        </Link>
        
        {myRoutes.map(({ id, children, isCategories }) => {
          if(isCategories){
            return (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText classes={{primary: classes.categoryHeaderPrimary}}>
                    {id}
                  </ListItemText>
                </ListItem>

                {children.map(({ id: childId, icon, path }) => (
                  <Link key={childId} to={path}>
                    <ListItem
                      key={childId}
                      button
                      className={clsx(classes.item, (window.location.pathname === path) && classes.itemActiveItem)}>
                        <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.itemPrimary,
                          }}>
                          {childId}
                        </ListItemText>
                    </ListItem>
                  </Link>
                ))}

                <Divider className={classes.divider} />
              </React.Fragment>
            );
          }
          return null
        })}
      </List>
    </Drawer>
  );
}

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

CustomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomDrawer);
