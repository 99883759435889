import React from 'react';
import Routes from "./Router"; 

import store from "./Redux/store";
import { Provider } from 'react-redux';

const App = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
);

export default App;
