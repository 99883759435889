import axios from "axios";
import store from "../Redux/store";
import {REQ_LOGOUT} from "../Redux/actionsTypes";

const api = axios.create({
//   baseURL: "http://192.168.0.103:8000"
  baseURL: "https://lorecontabilapi.loreweb.com.br"
});

api.interceptors.request.use(config => {
    var token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

api.interceptors.response.use(res => {
    if(!!res.data.errors){
        if(res.data.errors[0].message === "Unauthorized"){
            store.dispatch({
                type: REQ_LOGOUT, 
                payload: {
                    errorMsg: "Você foi deslogado porque o tempo limite de sessão expirou, faça login novamente para continuar."
                }
            });
        }
        return Promise.reject(res);
    }
    return Promise.resolve(res);
}, async function (error) {
    return Promise.reject(error);
});

export default api;