
import React from 'react';
import {
    Typography,
    Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';

import imgAndroid from "../../Assets/img/android.png";
import iconAppPlaceholder from "../../Assets/img/ic_app_placeholder.png";
import imagePlaceholder from "../../Assets/img/placeholder-300x202.jpg";

const SimulatorDevice = (props) => {
    const {icon, imageUrl, classes, title, body, appName, noImage} = props
    const [expanded, setExpansao] = React.useState(false);

    return (
        <div style={{backgroundColor: "whitesmoke", padding: "30px 20px"}}>

        <span onClick={() => setExpansao(false)} className={!expanded ? classes.expanActive : classes.expanNoActive}>Estado inicial</span>
        <span onClick={() => setExpansao(true)} className={!!expanded ? classes.expanActive : classes.expanNoActive}>Visualização expandida</span>

        <div className={classes.android}>
            <Grid container wrap="nowrap" className={classes.notification}>
                <Grid item md={12} zeroMinWidth>
                    <Grid container>
                        <Grid md={!expanded && !noImage ? 10 : 12}>
                            <div style={{paddingBottom: 5}}>
                                <img src={icon || iconAppPlaceholder} style={{marginTop: 3}} width={15} height={15} alt="device" />
                                <span className={classes.notificationInfo}>
                                    <Typography variant="caption" style={{fontSize: 10}} gutterBottom>{appName || "Nome do Aplicativo"}</Typography>
                                    <Brightness1Icon style={{position: "absolute", fontSize: 3, marginTop: 11, marginLeft: 5}} />
                                    <Typography variant="caption" style={{marginLeft: 12, fontSize: 10}} gutterBottom>Agora</Typography>
                                </span>
                            </div>

                            <Typography style={{fontWeight: 500, fontSize: 10}}>
                                {title.length === 0 ? `Título da notificação` : title}
                            </Typography>
                            
                            <Typography style={{color: "gray", fontSize: 10}} noWrap={!expanded}>
                                {body.length === 0 ? `Aqui ficará o corpo da mensagem, ao selecionar Visualização expandida irá aparecer toda a mensagem.` : body}
                            </Typography>
                        </Grid>
                        {!expanded && !noImage && <Grid md={2} style={{position: "relative"}}>
                            <div className={classes.imageNotificationThumb} style={{backgroundImage: `url(${imageUrl || imagePlaceholder})`}} />
                        </Grid>}
                    </Grid>
                    {!!expanded && !noImage && <div className={classes.imageNotification} style={{backgroundImage: `url(${imageUrl || imagePlaceholder})`}} />}
                </Grid>
            </Grid>
        </div>

        <div style={{textAlign: "center", marginTop: 10, color: "gray"}}>Android</div>
    </div>
    )
}

const styles = (theme) => ({
    expanActive: {
        padding: "10px 20px", 
        borderWidth: 1, 
        color: "#1a73e8", 
        borderColor: "#e9f4ff", 
        backgroundColor: "#dae5f4", 
        borderRadius: 10,
        cursor: "pointer"
    },
    expanNoActive: {
        color: "gray", 
        padding: "10px 20px", 
        cursor: "pointer"
    },
    notification: {
        marginTop: 72,
        padding: 10,
        marginBottom: 6,
        backgroundColor: "#fff"
    },
    android: {
        marginTop: 40,
        paddingLeft: 25,
        paddingRight: 25,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        display: "flex",
        backgroundImage: `url(${imgAndroid})`
    },
    notificationInfo: {
        position: "absolute",
        marginTop: -1,
        marginLeft: 8,
        color: "gray"
    },
    imageNotificationThumb: {
        marginTop: 7,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "absolute",
        right: 0,
        bottom: 3,
        height: 40,
        width: 40
    },
    imageNotification: {
        marginTop: 7,
        height: 150,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
    }
});

export default withStyles(styles)(SimulatorDevice);