import {REQ_GETMYAPPS, RES_GETMYAPPS} from '../actionsTypes';

const INIT_STATE = {
   loading: false,
   apps: []
}

export default (state = INIT_STATE, action) => {
   switch (action.type) {
      case REQ_GETMYAPPS:
         return { ...state, loading: true };

      case RES_GETMYAPPS:
         return { ...state, ...action.payload, loading: false };

      default:
         return state;
   }
}