import React from 'react';
import Hidden from '@material-ui/core/Hidden';

import Drawer from "../Drawer";

export default function Navigator(props) {
  const { classes, mobileOpen, handleDrawerToggle } = props;

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="js">
        <Drawer
          PaperProps={{ style: { width: 256 } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer PaperProps={{ style: { width: 256 } }} />
      </Hidden>
    </nav>
  );
}
