import {
   REQ_SENDNOTIFICATION, 
   REQ_GETMYNOTIFICATIONS
} from '../actionsTypes';

export const sendNotification = payload => ({
   type: REQ_SENDNOTIFICATION,
   payload
})

export const getMyNotifications = payload => ({
   type: REQ_GETMYNOTIFICATIONS,
   payload
})