
import { all, fork, call, put, delay, takeEvery } from 'redux-saga/effects';
import {REQ_LOGIN, RES_LOGIN, REQ_REGISTER, RES_REGISTER, REQ_LOGOUT, RES_LOGOUT, RES_SENDNEWPASSWORD, REQ_SENDNEWPASSWORD} from '../actionsTypes';
import { push } from "connected-react-router";
import api from "../../Service";

const asyncLogin = async payload => {
    const { data } = await api.post('/api/login', payload)
    return data;
}
function* login({payload}) {
    try {
        const login = yield call(asyncLogin, payload);
        yield localStorage.setItem("token", login.token);
        yield put(push("/dashboard"));
        yield put({ type: RES_LOGIN, payload: {} });
    } catch (error) {
        if(error.response.data.error === "invalid_credentials"){
            yield put({ type: RES_LOGIN, payload: { 
                errorEmail: true,
                errorPassword: true,
                errorMsg: "Login ou senha estão incorretos."
            }});
        }
        yield delay(10000);
        yield put({ type: RES_LOGIN, payload: { 
            errorEmail: false,
            errorPassword: false,
            errorMsg: ""
        }});
    }
}

const asyncNewPassword = async payload => {
    const { data } = await api.post('/api/newpassword', payload)
    return data;
}
function* newPassword({payload}) {
    try {
        const data = yield call(asyncNewPassword, payload);
        yield put({ type: RES_SENDNEWPASSWORD, payload: {errorMsg: data.message} });
        yield delay(4000);
        yield put({ type: RES_SENDNEWPASSWORD, payload: { 
            errorMsg: ""
        }});
    } catch (error) {
        yield put({ type: RES_SENDNEWPASSWORD, payload: {
            errorMsg: "Ocorreu um erro ao tentar alterar a senha."
        }});
        yield delay(6000);
        yield put({ type: RES_SENDNEWPASSWORD, payload: { 
            errorMsg: ""
        }});
    }
}


const asyncRegister = async payload => {
    const { data } = await api.post('/api/register', payload)
    return data;
}
function* register({payload}) {
    try {
        const register = yield call(asyncRegister, payload);
        yield localStorage.setItem("token", register.token);
        yield put(push("/dashboard"));
    } catch (error) {
        if(error.response.data.error !== undefined && error.response.data.error === "invalid_credentials"){
            yield put({ type: RES_LOGIN, payload: { 
                errorEmail: true,
                errorPassword: true,
                errorMsg: "Login ou senha estão incorretos."
            }});
        }else{
            const errorArr = JSON.parse(error.response.data);
            if(errorArr["email"] !== undefined){
                yield put({ type: RES_REGISTER, payload: { 
                    errorEmail: true,
                    errorPassword: false,
                    errorMsg: errorArr["email"][0]
                }});
            }
            if(errorArr["email"] === undefined && errorArr["password"] !== undefined){
                yield put({ type: RES_REGISTER, payload: { 
                    errorEmail: false,
                    errorPassword: true,
                    errorMsg: errorArr["password"][0]
                }});
            }
        }
        yield delay(10000);
        yield put({ type: RES_REGISTER, payload: { 
            errorEmail: false,
            errorPassword: false,
            errorMsg: ""
        }});
    }
}

const asyncLogout = async payload => {
    const {data} = await api.post('/api/logout')
    return data;
}
function* logout() {
    try {
        yield call(asyncLogout);
        yield localStorage.removeItem("token");
        yield window.location.href = "/";
        yield delay(10000);
        yield put({ type: RES_LOGOUT, payload: { 
            errorMsg: ""
        }});   
    } catch (error) {
        yield localStorage.removeItem("token");
        yield put(push("/"));
        yield delay(10000);
        yield put({ type: RES_LOGOUT, payload: { 
            errorMsg: ""
        }});
    }
}

export function* watchLogin() {
    yield takeEvery(REQ_LOGIN, login);
}

export function* watchNewPassword() {
    yield takeEvery(REQ_SENDNEWPASSWORD, newPassword);
}

export function* watchRegister() {
    yield takeEvery(REQ_REGISTER, register);
}

export function* watchLogout() {
    yield takeEvery(REQ_LOGOUT, logout);
}

export default function* rootSaga() {
   yield all([
        fork(watchLogin),
        fork(watchLogout),
        fork(watchRegister),
        fork(watchNewPassword)
   ]);
}