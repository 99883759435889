import {
   REQ_LOGIN, 
   REQ_REGISTER, 
   REQ_LOGOUT,
   REQ_SENDNEWPASSWORD
} from '../actionsTypes';

export const login = payload => ({
   type: REQ_LOGIN,
   payload
})

export const register = payload => ({
   type: REQ_REGISTER,
   payload
})

export const logout = () => ({
   type: REQ_LOGOUT
})

export const sendNewPassword = payload => ({
   type: REQ_SENDNEWPASSWORD,
   payload
});